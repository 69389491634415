/* jshint esversion: 6 */

import AOS from 'aos';

let delay = 0;
$('[data-aos]').each(function() {
   if (isElementInViewport($(this))) {
      if ($(this).hasClass('list-inline-item')) {
        // accelerate contact list icons animation
        delay = delay + 100;
      } else {
        delay = delay + 300;
      }
      
      $(this).attr('data-aos-delay', delay);
   }
});

AOS.init({
  offset: 0,
  once: true
});

function isElementInViewport(el) {
  if (typeof jQuery === "function" && el instanceof jQuery) {
    // Using jQuery
    el = el[0];
  }

  const rect = el.getBoundingClientRect();

  return (rect.bottom >= 0 && rect.right >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight) && rect.left <= (window.innerWidth || document.documentElement.clientWidth));
}
